<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" placeholder="活动名称" style="width: 200px;;margin-right: 10px;"></el-input>
				<label class="label">活动状态:</label>
				<el-select
					v-model="activeType"
					placeholder="请选择"
					filterable
					clearable
					style="width: 160px;margin-right: 10px;"
				>
					<el-option
						v-for="(item,index) in activityStateList"
						:key="index"
						:label="item.str"
						:value="item.state"
					></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<button-permissions :datas="'addDiscounts'">
					<el-button type="primary" style="margin-left:15px;width:90px;" @click="discountAdd">添加</el-button>
				</button-permissions>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="activityName" label="活动名称" width="170"></el-table-column>
				<el-table-column label="活动时间" width="170">
					<template slot-scope="scope">
						<span>{{scope.row.begTime}}</span>
						<span style="margin:0 5px">至</span>
						<span>{{scope.row.endTime}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="ActivityStateValue" label="状态"></el-table-column>
				<el-table-column label="支付订单">
					<template slot-scope="scope">
						<span v-if="scope.row.orderNumber==0">0</span>
						<el-button v-else type="text" @click="handleRouteJump(scope.row)">{{scope.row.orderNumber}}</el-button>
						<!-- <div style="color: #409EFF;">{{scope.row.OrderCount}}</div> -->
					</template>
				</el-table-column>
				<el-table-column prop="peoPleNumber" label="支付人数"></el-table-column>
				<el-table-column label="支付金额">
					<template slot-scope="scope">
						<span>{{+scope.row.amount}}</span>
					</template>
				</el-table-column>
				<el-table-column label="客单价">
					<template slot-scope="scope">
						<span>{{+scope.row.peolpeAmount}}</span>
					</template>
				</el-table-column>
				<el-table-column label="笔单价">
					<template slot-scope="scope">
						<span>{{+scope.row.orderAmount}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="155"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button
							type="text"
							@click="handleOnlyLook(scope.row)"
						>查看</el-button>
						<button-permissions :datas="'editDiscounts'" v-if="scope.row.activityStatus==0||scope.row.activityStatus==1">
							<el-button style="margin-left: 10px;" type="text" @click="handleCopy(scope.row,2)">编辑</el-button>
						</button-permissions>
						<button-permissions :datas="'copyDiscounts'">
							<el-button style="margin-left: 10px;" type="text" @click="handleCopy(scope.row,1)">复制</el-button>
						</button-permissions>
						<button-permissions
							style="margin:0 10px;"
							:datas="'deleteDiscounts'"
						>
							<el-button type="text" style="color:#f00;" @click="deleteChose(scope.row)">删除</el-button>
						</button-permissions>
						
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
				<el-pagination
					v-if="Total"
					style="margin-top:20px;float:right;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="Total"
				></el-pagination>
			</div>
		</div>

		<!-- 品牌名称编辑的弹框 -->
		<el-dialog :visible.sync="editVisable" width="50%" title="添加品牌" :show-close="false">
			<div style="display: flex;border-top: 1px solid #F0F2F5;padding-top: 20px;">
				<label class="label">品牌名称:</label>
				<el-input v-model="groupName" placeholder="最多输入16个字" maxlength="16" clearable style="flex:1"></el-input>
			</div>

			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="saveChange(0)">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange(1)">保存</el-button>
			</div>
		</el-dialog>

		<!-- 使终止按钮 -->
		<el-dialog :visible.sync="stopDialogShow" width="400px" center :show-close="false">
			<div style="line-height: 25px;">活动终止后，你将不可再对这场活动进行编辑，是否确认使失效？</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="stopActive(0)">取 消</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="stopActive(1)">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>
	</div>
</template>

<script>
import {
	timelimitdiscountlist,
	timelimitdiscountsetisopen,
	timelimitdiscountdelete
} from '@/api/goods'

import buttonPermissions from '@/components/buttonPermissions';
import batchCods from "@/components/bathCodes"

export default {
	components: {
		buttonPermissions,
		batchCods
	},
	data () {
		return {
			weiCodeData: {},
			weiCodeShow: false,
			bathSortDatas: [],
			groupData: [],
			roleList: [],
			searchKey: '',
			saleType: '', //销售类型
			goodsType: '', //商品分组
			loading: false,
			editVisable: false,
			groupName: "",
			activeType: null,
			activityStateList: [{
				state: null,
				str: '全部'
			},
			{
				state: 0,
				str: '未开始'
			},
			{
				state: 1,
				str: '进行中'
			},
			{
				state: 2,
				str: '已结束'
			},
			],

			stopDialogShow: false, //终止提示弹窗
			stopData: null, //终止数据

			currentPage: 1,
			pageSize: 10,
			Total: 1,
			checkedAll: false, //当前页全选
			checked: false, //是否 仅看可选商品
			addProductDialogShow: false,
			currentPage1: 1,
			pageSize1: 20,
			Total1: 3,
		};
	},
	created () {
		this.getList();
	},
	methods: {
		//支付订单跳转
		handleRouteJump (val) {
			this.$router.push({
				path: '/order/orderMange',
				query: {
					ActivityId: val.ActivityFullId,
					ActivityType: 1,
					starTime: val.StartTime,
					endTime: val.EndTime,
				}
			})
		},
		async getList () {
			this.loading = true;
			const res = await timelimitdiscountlist({
				pageNo: this.currentPage,
				pageSize: this.pageSize,
				keyWords: this.searchKey,
				actType: this.activeType,//活动状态 0未开始 1：活动中，2 已结束,
			});

			if (res.success) {
				this.groupData = res.data.records;
				this.Total = res.data.total;
				this.groupData.map(t => {
					t.orderAmount = t.orderAmount.toFixed(2);
					t.peolpeAmount = t.peolpeAmount.toFixed(2);
					t.amount = t.amount.toFixed(2);
					if(t.activityStatus==0){
						t.ActivityStateValue = "未开始";
					}else if(t.activityStatus==1){
						t.ActivityStateValue = "进行中" ;
					}else if(t.activityStatus==2){
						t.ActivityStateValue = "已结束";
					}
					return t;
				})
			}else{
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg ? res.alertMsg : '请求失败，请重试'
				});
			}
			this.loading = false;
		},
		discountAdd () {
			this.$router.push({
				path: '/market/addDiscount'
			})
		},
		goodsSearch () {
			this.currentPage = 1;
			this.getList();
		},
		eidtGroup (item) {
			this.editVisable = true
			console.log(item);
		},
		deleteChose (record) {
			this.$confirm('是否确认删除本场活动？删除后不可恢复', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.sureDelete(record)
			}).catch(() => { });
		},
		// 推广
		handleTablePromote (id) {
			this.weiCodeShow = true;
			this.weiCodeData = {
				Page: 'pages/f/f',
				Scene: `id=${id}`
			}
		},
		weiCodeCancel (val) {
			this.weiCodeShow = false;
			console.log(val);
		},
		async sureDelete (record) {
			const res = await timelimitdiscountdelete({
				id: record.id
			})

			if (res.success) {
				this.$message({
					showClose: true,
					type: 'success',
					message: '删除成功'
				});

				this.getList();
			}else{
				this.$message({
					showClose: true,
					type: 'error',
					message: '操作失败，请重试'
				});
			}
		},
		saveChange (type) {
			this.editVisable = false
			//type为1是保存
			console.log(type);
		},
		stopBtn (record) {
			this.stopDialogShow = true;
			this.stopData = record;
		},
		stopActive (type) {
			this.stopDialogShow = false
			if (type === 1) {
				this.sureStop();
			}
		},
		async sureStop () {
			const res = await timelimitdiscountsetisopen({
				ActivityFullId: this.stopData.ActivityFullId
			})
			if (res.IsSuccess) {
				this.$message({
					showClose: true,
					message: '终止成功',
					type: 'success'
				})
				this.getList();
			}
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},

		// 翻页
		handleCurrentChange (val) {
			this.currentPage = val;
			this.getList();
		},
		productSelectChange () {

		},
		// 商品弹窗切换显示条数
		productSizeChange (val) {
			this.pageSize1 = val;
			//this.getList();
		},

		// 商品弹窗翻页
		productCurrentChange (val) {
			this.currentPage1 = val;
			//this.getList();
		},
		tabCheckedChange () {
			this.$refs['productChose'].toggleAllSelection();
		},

		jumToEdit (record) {
			let activeState = 1
			if (record.ActivityStateValue == '进行中') {
				activeState = 1
			} else if (record.ActivityStateValue == '已结束') {
				activeState = 2
			}
			this.$router.push({
				path: '/market/addDiscount',
				query: {
					id: record.id,
					activeState: activeState
				}
			})
		},
		handleOnlyLook (record) {
			let activeState = 1
			if (record.ActivityStateValue == '进行中') {
				activeState = 1
			} else if (record.ActivityStateValue == '已结束') {
				activeState = 2
			}
			this.$router.push({
				path: '/market/addDiscount',
				query: {
					id: record.id,
					onlylook: 1,
					activeState: activeState
				}
			})
		},
		handleCopy (record,type) {
			this.$router.push({
				path: '/market/addDiscount',
				query: {
					id: record.id,
					copy: type
				}
			})
		},
	}
}
</script>

<style>
</style>
